@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: local("Proxima Nova Reg"), local("ProximaNova-Reg"), url(./assets/fonts/ProximaNova-Reg.otf) format("opentype"); }

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  src: local("Proxima Nova Sbold"), local("ProximaNova-Sbold"), url(./assets/fonts/ProximaNova-Sbold.otf) format("opentype"); }

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 100;
  src: local("Proxima Nova Thin"), local("ProximaNova-Thin"), url(./assets/fonts/ProximaNova-Thin.otf) format("opentype"); }

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif; }
